/* eslint-disable react/no-array-index-key */
import { useRouter } from 'next/router'
import { Anchor, Button } from '@mantine/core'
import Link from 'next/link'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useAccount } from 'wagmi'
import useAuth from 'hooks/useAuth'
import { useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import { ChainId } from '@pancakeswap/sdk'
import Logo from './_images/logo.svg'

const ROUTES = [
  { name: 'Swap', pathname: '/' },
  { name: 'Pools', pathname: '/pools' },
  { name: 'Liquidity', pathname: '/liquidity' },
]

export const Header = () => {
  const router = useRouter()
  const { pathname } = router
  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const switchNetworkLocal = useSwitchNetworkLocal()

  return (
    <div className="relative z-10 flex items-center justify-between h-20 w-full max-w-screen-lg px-4 mx-auto lg:max-w-none lg:px-24">
      <div className="flex items-center gap-4 lg:gap-0">
        <Anchor component={Link} className="relative group hidden lg:block" href="/" underline="never">
          <div className="absolute inset-0 flex items-center justify-center space-x-2 transition-all duration-75 group-hover:opacity-0">
            <div className="lg:size-2 bg-lime-9" />
            <Logo />
            <div className="lg:size-2 bg-lime-9" />
          </div>
          <div className="flex items-center space-x-2 transition-all duration-75 opacity-0 group-hover:opacity-100">
            <div className="lg:size-2 bg-lime-9" />
            <div className="font-bold text-lime-9">OpenSwap</div>
            <div className="lg:size-2 bg-lime-9" />
          </div>
        </Anchor>

        <div className="flex space-x-4 lg:ml-24 lg:space-x-16">
          {ROUTES.map((route, index) => (
            <Anchor
              key={index}
              className={`uppercase ${pathname === route.pathname ? 'font-bold' : 'font-medium'}`}
              component={Link}
              href={route.pathname ?? ''}
            >
              {route.name}
            </Anchor>
          ))}
        </div>
      </div>

      <div className="flex">
        {isConnected ? (
          <Button
            variant="filled-outline"
            onClick={() => {
              logout().then(() => {
                switchNetworkLocal(ChainId.RSS3)
              })
            }}
          >
            Disconnect
          </Button>
        ) : (
          <ConnectWalletButton>
            <Button variant="filled-outline">Connect Wallet</Button>
          </ConnectWalletButton>
        )}
      </div>
    </div>
  )
}
